import { Component, Injector, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginComponent } from './login/login.component';
// import { DuoService } from '@shared/services/duo.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {  
  // private loginComponent: LoginComponent | null = null;

  constructor(
    private injector: Injector,
    private router: Router,
    private loginComponent: LoginComponent,
  ) {
    // if (this.isDesktopDevice) {
    //   this.loginComponent = this.injector.get(LoginComponent);
    // }else{
    // }
    if (window.location.pathname.replace('/', '') === "duocallback/") {
      var state: string = ""
      var code: string = ""

      state = decodeURIComponent(window.location.search.replace('?', '').split('&')[0].split('=')[1]);
      code = decodeURIComponent(window.location.search.replace('?', '').split('&')[1].split('=')[1]);

      // const fireUser = this.commonService.getLocalStorage('fireUser');
      // const resData = this.commonService.getLocalStorage('resData');
      // this.commonService.setLocalStorage('isDuoVerified', JSON.stringify(0));
      // console.log("state:" + state);
      // console.log("code:" + code);
      // here we need to call the java service to get the state for user.
      // if state match then redirect to the dashboard else to the login.
      // this.duoService.GetDuoUserState(resData.userid , resData.token).subscribe((res) => {
      // console.log(res);
      // if (this.commonService.getLocalStorage('duoState') === state) {
      //   //redirect to dashboard
      //   this.commonService.setLocalStorage('isDuoVerified', JSON.stringify(1));
        // if(this.loginComponent){
          this.loginComponent.validateUser('fireUser, resData'); //commented for mobile issue
        // }
      // } else {
      //   this.router.navigate(['/login']);
      // }
    }
  }



  ngOnInit(): void {
    
  }



  // async unlockOrientation() {
  //   await ScreenOrientation.unlock();
  // }



  isMobile!: boolean;
  isTablet!: boolean;
  isDesktopDevice!: boolean;
  osType!: string;
  isIOS!: boolean;
  isAndroid!: boolean;
}