import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DuoService {

  private duourl = "https://qonductor-dev.myqone.com/qduoconnect/duo-init/";

  constructor(private http: HttpClient) { }

  InitiateDuo(myQoneUserID: any, username: string, redirectionurl: string, token: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    const body = { username, redirectionurl, myQoneUserID };
    return this.http.post<any>(this.duourl, body, { headers });
  }

  GetDuoUserState(userid: string, token: string): Observable<any> {
    const headers = new HttpHeaders().set('Authorization', 'Bearer ' + token);
    return this.http.get<any>('https://qore-dev.myqone.com/auth/DuoUserStateByMyQoneUserid/' + userid, { headers });
  }
}
