import { DatePipe } from '@angular/common';
import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Subscription, forkJoin } from 'rxjs';
import { DuoService } from '../duo.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
// @Injectable()
export class LoginComponent implements OnInit, OnDestroy {
  public errorMessage: string = 'Please check your credentials and try again.';
  //log("login");
  token : any;
  subscription: Subscription = new Subscription();
  public userloggedin: boolean = false;
  breakpoint: any = window.matchMedia('(min-width: 991px)');
  isDesktop: boolean;
  hide: boolean = true;
  navigationExtras: NavigationExtras = {
    replaceUrl: true // This will replace the current URL in the history
  };
  state: any;
  isDuoVerified: boolean = false;
  constructor(
    private duoService: DuoService,
  ) {
    this.isDesktop = !!this.breakpoint.matches;
    this.isDuoVerified = false;
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    // console.log('Destroy Called');
    if (this.subscription != undefined) {
      this.subscription.unsubscribe();
    }
  }

  async getLoginUserDetails() {
    const resData = { userid: ' ', token: ' ' };
    this.duoService.InitiateDuo(resData.userid, 'suyog.gujarathi@triarqhealth.com', window.location.origin.toString() + "/duocallback/", resData.token).subscribe({
      next: (data: any) => {
        try {
          this.duoService.GetDuoUserState(resData.userid, resData.token).subscribe((res) => {
            if (res) {
              // this.commonService.setLocalStorage('duoState', JSON.stringify(res.duostate));
              // this.commonService.setLocalStorage('resData', JSON.stringify(resData));
              // this.commonService.setLocalStorage('fireUser', JSON.stringify(fireUser));
              window.open(data.promptUri, "_self");
            }
          });
          //this.validateUser(fireUser, resData, this.state);
        } catch (error) {
          console.error('Error setting localStorage items:', error);
        }
      },
      error: (error: any) => { console.error('Duo Service Error:', error); },
      complete: () => {
      }
    });
  }

  validateUser(data: any) {
    console.log("Login component from navogation");
  }

  redirect(): void {
    window.open('https://chatgpt.com', '_self');
  }
}