import { Component } from '@angular/core';

@Component({
  selector: 'app-duo-notification',
  templateUrl: './duo-notification.component.html',
  styleUrls: ['./duo-notification.component.css']
})
export class DuoNotificationComponent {

}
